<template>
  <div v-if="setupManuscriptFlag" class="setupManuscript">
		<div class="header">
			<div class="title">稿酬计算设置</div>
			<img @click="close" style="width: 15px;height:15px;cursor: pointer;" src="../../assets/images/close.png" />
		</div>
		<div class="content" v-loading="loading">
			<div class="configItem" v-for="(item, index) in setupData" :key="index">
				<div class="configItemName">{{item.appName}}</div>
				<div style="display: flex;align-items: center;margin-bottom:10px">
					<div class="configItemLabel">基础稿酬倍率：</div>
					<el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="item.baseMultiple"></el-input>
				</div>
				<div v-if="item.id == '3'" style="display: flex;align-items: center;margin-bottom:10px">
					<div class="configItemLabel">固定稿酬：</div>
					<el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="item.originalFee"></el-input>
				</div>
					<div v-else style="display: flex;align-items: center;margin-bottom:10px">
						<div class="configItemLabel">原创奖励：</div>
						<el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="item.originalFee"></el-input>
					</div>
					<div v-if="item.id != '3'" style="display: flex;align-items: center;margin-bottom:10px">
						<div class="configItemLabel">特约稿酬：</div>
						<el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="item.specialFee"></el-input>
					</div>
			</div>
		</div>
		<div class="footer">
			<div class="comfirn" @click="comfirn">确定</div>
		</div>
  </div>
</template>
<script>
import {getSetupConfig, editSetupConfig} from '../../api/userHome.js'
export default {
	props: ['setupManuscriptFlag'],
  data() {
    return {
			setupData: [],
			loading: false
    }
  },
	watch: {
		setupManuscriptFlag(newVal) {
			if(newVal) {
				this.setupConfig()
			}
		}
 	},
  created() {
  },
  methods: {
		async comfirn() {
			let params = []
			let flag = false
			for(let i=0; i<this.setupData.length;i++) {
				if(this.setupData[i].id != '3') {
					if(!this.setupData[i].baseMultiple && this.setupData[i].baseMultiple !=0) {
						this.$message({
							message: `请输入${this.setupData[i].appName}基础稿酬倍率`,
							type: 'error'
						});
						flag = true
						break
					}
				}
				if(!this.setupData[i].originalFee && this.setupData[i].baseMultiple !=0) {
					this.$message({
						message: `请输入${this.setupData[i].appName}原创奖励`,
						type: 'error'
					});
					flag = true
					break
				}
				if(this.setupData[i].id != '3') {
					if(!this.setupData[i].specialFee) {
						this.$message({
							message: `请输入${this.setupData[i].appName}特约稿酬`,
							type: 'error'
						});
						flag = true
						break
					}
				}
			}
			if(flag) {
				return
			}
			this.setupData.forEach(item => {
				params.push({
					id: item.id,
					baseMultiple: item.baseMultiple,
					originalFee: item.originalFee,
					specialFee: item.specialFee
				})
			})
			let res = await editSetupConfig(params)
			if(res.code == 200) {
				this.$message({
          message: '设置完成',
          type: 'success'
        });
				this.$emit('setupManuscriptConfirm')
			}
		},
		async setupConfig() {
			this.loading = true
			let res = await getSetupConfig()
			this.loading = false
			if(res.code == 200) {
				this.setupData = res.data
			}
		},
		close() {
			this.$emit('closeSetupManuscriptConfirm')
		}
  },
}
</script>
<style lang="scss" scoped>
.setupManuscript {
	//height: 320px;
	width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
	background: #FFF;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	.footer {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: center;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.comfirn {
			//width: 68px;
			cursor: pointer;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #B9B9B9;
			background: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			box-sizing: border-box;
			line-height: 14px;
			font-size: 14px;
			color: #747474;
		}
	}
	.content {
		padding: 10px 20px;
		flex: 1;
		display: flex;
		flex-direction: column;
		.configItem {
			display: flex;
			flex-direction: column;
			.configItemName {
				line-height: 22px;
				font-weight: bold;
				font-size: 14px;
				color: #303133;
				margin-bottom: 10px;
			}
			.configItemLabel {
				line-height: 22px;
				font-size: 14px;
				color: #303133;
				flex: 0 0 100px;
			}
		}
	}
	.header {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.title {
			color: #262626;
			font-size: 20px;
			font-weight: bold;
			line-height: 30px;
		}
	}
}
</style>
