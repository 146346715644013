import { render, staticRenderFns } from "./manuscriptIncomeCompenent.vue?vue&type=template&id=3e86e392&scoped=true&"
import script from "./manuscriptIncomeCompenent.vue?vue&type=script&lang=js&"
export * from "./manuscriptIncomeCompenent.vue?vue&type=script&lang=js&"
import style0 from "./manuscriptIncomeCompenent.vue?vue&type=style&index=0&id=3e86e392&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e86e392",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/macos/workspaces/winning2024/pdf-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3e86e392')) {
      api.createRecord('3e86e392', component.options)
    } else {
      api.reload('3e86e392', component.options)
    }
    module.hot.accept("./manuscriptIncomeCompenent.vue?vue&type=template&id=3e86e392&scoped=true&", function () {
      api.rerender('3e86e392', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/thys/view/userHome/component/manuscriptIncomeCompenent.vue"
export default component.exports