/**下载文件公共方法 */
import { Message } from 'element-ui'
function downloadHandle(res) {
  // console.log(res)
  // console.log(res.headers)
  let downloadName = ''
  // downloadName = decodeURI(res.headers['filename'])
  // console.log(decodeURI(res.headers['content-disposition']).split('filename='))
  downloadName = decodeURI(res.headers['content-disposition']).split('filename=')[1]
  // let downloadName = `${new Date().getTime()}.xlsx`
  // if (this.formData.downloadType === 2) {
  // 	// json
  // 	downloadName = `${new Date().getTime()}.json`
  // }
  let url = window.URL.createObjectURL(new Blob([res.data]))
  let link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', downloadName)
  document.body.appendChild(link)
  link.click()
  // Message({
  //   message: '下载成功',
  //   type: 'success'
  // })
}
const onStartDownload = async function(res, callback) {
  if (!res) {
    return
  }
  let data = res.data || res
  let fileReader = new FileReader()
  let result = null
  fileReader.onload = function() {
    try {
      console.log(this.result)
      let jsonData = JSON.parse(this.result)  // 说明是普通对象数据，后台转换失败
      if (jsonData.code) {
        Message({
          message: jsonData.msg,
          type: 'warning'
        })
      }
      const { code } = jsonData
      if (code !== 200 && jsonData.error) {
        // 机构药品库下载标准数据 msg: "当前状态为【未知】,不可下载"
        return
      }
      if (code !== 500 && code !== 400 && code !== 401 && code !== 403 && code !== 404) {
        downloadHandle(res) // json文件下载
      } else {
        // 当每个接口有不同返回码的时候，在各自对应的文件中做处理
        callback && callback()
      }
    } catch (err) {   // 解析成对象失败，说明是正常的文件流
      console.log(err)
      downloadHandle(res)
      if (callback) {
        callback()
      }
    }  
  }
  // if (data instanceof Blob) {
  //   result = fileReader.readAsText(data, "UTF-8")
  // } else {
  //   result = data
  // }
  result = fileReader.readAsText(data, "UTF-8")
}
export { onStartDownload }
