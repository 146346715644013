<template>
  <div v-if="uploadExcelFlag" class="uploadExcel">
		<div class="header">
			<div class="title">导出Excel</div>
			<img @click="close" style="width: 15px;height:15px;cursor: pointer;" src="../../assets/images/close.png" />
		</div>
		<div class="content">
			<div>结算月：</div>
			<el-select size="mini" v-model="month" placeholder="请选择">
				<el-option
					v-for="item in monthOptionList"
					:key="item.value"
					:label="item.label"
					:value="item.value">
				</el-option>
			</el-select>
		</div>
		<div class="footer">
			<div class="comfirn" @click="comfirn">确认</div>
		</div>
  </div>
</template>
<script>
import {receiveManuscript} from '../../api/userHome.js'
import {updateManuscript} from '../../api/home.js'
import { getToken } from '@/utils/auth'
import {getMonthList, uploadExcel} from '../../api/userHome.js'
import {
  onStartDownload
} from '@/utils/downloadFile'
export default {
	props: ['uploadExcelFlag'],
  data() {
    return {
			monthOptionList: [],
			month: ''
    }
  },
	watch: {
		uploadExcelFlag(newVal) {
			if(newVal) {
				this.monthList()
			}
		}
	},
  created() {
  },
  methods: {
		async comfirn() {
			if(!this.month) {
				this.$message.error('请选择结算月')
				return
			}
			let res = await uploadExcel(this.month)
			onStartDownload(res)
			this.$message({
				message: '完成',
				type: 'success'
			});
			this.month = ''
			this.$emit('closeUploadExcel')
		},
		async monthList() {
			let res = await getMonthList()
			if(res.code == 200) {
				this.monthOptionList = []
				res.data.forEach(item => {
					this.monthOptionList.push({
						value: item,
						label: item
					})
				})
			}
		},
		close() {
			this.month = ''
			this.$emit('closeUploadExcel')
		}
  },
}
</script>
<style lang="scss" scoped>
.uploadExcel {
	//height: 320px;
	width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
	background: #FFF;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	.footer {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: center;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.comfirn {
			//width: 68px;
			cursor: pointer;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #B9B9B9;
			background: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			box-sizing: border-box;
			line-height: 14px;
			font-size: 14px;
			color: #747474;
		}
	}
	.content {
		padding: 10px 20px;
		flex: 1;
		display: flex;
	}
	.header {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.title {
			color: #262626;
			font-size: 20px;
			font-weight: bold;
			line-height: 30px;
		}
	}
}
</style>
